.suppressConfirmation {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}

.suppress_container_box {
  /* height: 354px; */
  padding: 15px;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid #c5c5c5;
  /* opacity: 0px; */
}

.want_to_continue {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.box_container2 {
  height: 126px;
  width: 897px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #2871c7;
  /* opacity: 0px; */
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.34px;
  text-align: left;
  border-color: #c5c5c5;
}

.alert_suppress_text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.date_text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.date_field {
  color: #898989;
  width: 228px;
  height: 36px;
  padding: 0 10px 0 10px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  /* opacity: 0px; */
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
  background: url("../Icons/dateIcon.svg") no-repeat right center;
}

.time_text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.time_input {
  color: #898989;
  width: 228px;
  height: 36px;
  padding: 0 10px 0 10px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  /* opacity: 0px; */
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
  background: url("../Icons/timeIcon.svg") no-repeat right center;
}

.confirm_btn {
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  /* opacity: 0px; */
  background-color: #2787e4;
  color: white;
  border: none;
  /* confirm button text style */
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
  text-align: left;
}

.cancel_btn {
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  /* opacity: 0px; */
  border: none;
  background-color: #ff2929;
  /* cancel button text style */
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
  text-align: left;
  color: white;
}
