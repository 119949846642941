.title {
  font-family: Poppins;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #3e3c3c;
}
.button-tabs {
  display: flex;
  gap: 10px;
}

.tab-button {
  min-width: 100px; /* Ensure buttons are wide enough */
  font-family: Noto Sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 21.79px;
  color: #596780;
  border: 1px solid #a9a9a9;
  border-radius: 12px;
}

.tab-button:focus,
.tab-button:active,
.tab-button:hover {
  background-color: #2787e4 !important;
  border: 1px solid #2787e4 !important;
}

.tab-button .btn-primary {
  border: transparent !important;
}
