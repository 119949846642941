.table_heading {
  font-family: Noto Sans !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 27.07px !important;
  text-align: left !important;
}

th {
  white-space: nowrap;
}

.td_items {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
}

.supress_btn {
  padding: 4px 12px 4px 12px;
  background-color: #0d7de8;
  border-radius: 8px;
  border: 0.5px solid #007aff;
  color: white;
  /* opacity: 0px; */
}

.alertId_link {
  text-decoration: none;
}

.custom_tooltip {
  background-color: #ffffff !important;
  font-family: Noto Sans !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  width: 200px !important;
  height: 35px !important;
  text-align: center;
  padding-top: 5px;
}

.modal-createAlert .modal-content {
  height: 192px !important;
  padding-top: 17px;
}

.modal-createHeal .modal-content {
  height: 271px !important;
}

.ok_button_alert {
  top: 50px !important;
  height: 46px !important;
  width: 226px !important;
  border-radius: 8px !important;
  background: #c5c5c5 !important;
  color: white !important;
  border: none !important;
  font-size: 16px;
  font-family: Noto Sans;
  margin-bottom: 20px;
}

.ok_button_heal {
  top: 50px !important;
  height: 46px !important;
  width: 226px !important;
  border-radius: 8px !important;
  background: #2787e4 !important;
  color: white !important;
  border: none !important;
  font-size: 16px;
  font-family: Noto Sans;
  margin-bottom: 20px;
}
