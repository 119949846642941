.add_account {
  color: #3e3c3c;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}

.dropdown_btn {
  border: none;
  background-color: transparent;
  float: right;
}

.cloud_service_container {
  width: Fixed (1, 034px) px;
  height: Hug (76px) px;
  padding: 20px;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid #c5c5c5;
  /* opacity: 0px; */
}

.cloud_service_text {
  position: relative;
  top: 5px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.astrick {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #ff2929;
}

.dropdown_field_cloud {
  font-size: 14px !important;
  font-family: Noto Sans !important;
  width: 228px !important;
  height: 36px;
  background-color: transparent !important;
  color: #898989 !important;
  text-align: left;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_field_cloud:focus {
  border: 1px solid #2871c7;
}

.dropdown_field_cloud:hover {
  border: 1px solid #c5c5c5 !important;
}
.dropdown_menu_cloud {
  width: 228px;
  padding: 4px;
}

.dropdown_item_cloud:hover {
  background-color: #a3ccf4;
  border-radius: 8px;
}
.dropdown_item_cloud {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
}

.form_group {
  display: flex;
  align-self: center;
}

.awsForm_container {
  display: flex;
  align-self: center;
}

.account_owner {
  position: relative;
  top: 5px;
}

.acc_owner_text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.inputtext_aws {
  width: 228px;
  height: 36px;
  padding: 5px;
  gap: 23px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  /* opacity: 0px; */
  margin-left: 36px;
}

.inputtext_access_key {
  width: 228px;
  height: 36px;
  padding: 5px;
  gap: 23px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  /* opacity: 0px; */
  margin-left: 118px;
}

.form_container {
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.right_coloum {
  margin-right: 230px;
}

.acc_id_input {
  width: 228 px;
  height: 36px;
  padding: 14px 12px 14px 12px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  /* opacity: 0px; */
}

.dropdown_aws {
  font-size: 14px !important;
  font-family: Noto Sans !important;
  width: 228px !important;
  height: 36px;
  background-color: transparent !important;
  color: #898989 !important;
  text-align: left;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_aws:focus {
  border: 1px solid #2871c7;
}

.dropdown_aws:hover {
  border: 1px solid #c5c5c5 !important;
}

.dropdown_azure {
  font-size: 14px !important;
  font-family: Noto Sans !important;
  width: 228px !important;
  height: 36px;
  background-color: transparent !important;
  color: #898989 !important;
  text-align: left;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_azure:focus {
  border: 1px solid #2871c7;
}

.dropdown_azure:hover {
  border: 1px solid #c5c5c5 !important;
}

.dropdown_gcp {
  font-size: 14px !important;
  font-family: Noto Sans !important;
  width: 228px !important;
  height: 36px;
  background-color: transparent !important;
  color: #898989 !important;
  text-align: left;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_gcp:focus {
  border: 1px solid #2871c7;
}

.dropdown_gcp:hover {
  border: 1px solid #c5c5c5 !important;
}
