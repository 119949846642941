.button_container {
  display: flex;
}

.logIn_button {
  background-color: white;
  color: black;
  width: 162px;
  height: 52.93px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-top-right-radius: none;
  border-bottom-right-radius: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: background-color 0.3s ease;
  border: 1px solid #c5c5c5;
  border-right: none;
}

.logIn_button.active {
  background-color: #2787e4;
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.login_text {
  position: relative;
  top: 7px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}

.signUp_text {
  position: relative;
  top: 7px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}

.org_email_text {
  position: relative;
  bottom: 12px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.org_email_field {
  height: 45px;
  width: 324px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  padding: 10px;
  outline: none;
  font-size: 14px;
  font-family: Noto Sans;
}

.email_field:focus {
  border: 1px solid #2871c7;
  outline: none;
}

.fullname_container {
  position: relative;
  top: 30px;
}

.org_fullname {
  position: relative;
  bottom: 18px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.fullname_field {
  position: relative;
  bottom: 6px;
  height: 45px;
  width: 324px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  padding: 10px;
  outline: none;
  font-size: 14px;
  font-family: Noto Sans;
}

.fullname_field:focus {
  border: 1px solid #2871c7;
  outline: none;
}

.department_container {
  position: relative;
  top: 30px;
}

.dep_fullname {
  position: relative;
  bottom: 13px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.dep_name_field {
  font-size: 14px;
  font-family: Noto Sans;
  height: 45px;
  width: 324px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  padding: 10px;
  outline: none;
}

.dep_name_field:focus {
  border: 1px solid #2871c7;
  outline: none;
}

.role_container {
  position: relative;
  top: 30px;
}

.dropdown_field_signup {
  font-size: 14px !important;
  font-family: Noto Sans !important;
  width: 324px !important;
  height: 45px;
  background-color: transparent !important;
  color: #898989 !important;
  text-align: left;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_field_signup:focus {
  border: 1px solid #2871c7;
}

.dropdown_field_signup:hover {
  border: 1px solid #c5c5c5 !important;
}
.dropdown_menu_signup {
  width: 324px;
  padding: 4px;
}

.dropdown-item-signup:hover {
  background-color: #a3ccf4;
  border-radius: 8px;
}

.role {
  position: relative;
  bottom: 13px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.request_container {
  position: relative;
  top: 30px;
}

.request_reason {
  position: relative;
  bottom: 13px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.request_field {
  height: 104px;
  width: 324px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  padding: 10px;
  outline: none;
  padding-bottom: 70px;
  font-size: 14px;
  font-family: Noto Sans;
  overflow: hidden;
  resize: none;
}

.signIn_btn {
  position: relative;
  top: 55px;
  height: 48px;
  width: 324px;
  border-radius: 8px;
  background: #2787e4;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logIn_btn_text {
  position: relative;
  top: 7px;
  font-family: Noto Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: center;
}

.modalStyle-msg-signup .modal-dialog {
  max-width: 561px !important;
}

.modalStyle-msg-signup .modal-content {
  height: 292px !important;
  padding: 20px !important;
  padding-top: 0px !important;
}

.submit-icon1 {
  cursor: pointer;
  margin-top: 30px;
  text-align: center;
}
.content {
  text-align: center;
}
.pswrd-sent-text {
  font-family: poppins;
  font-size: 16px;
  font-weight: 600;
}
.pswrd-sent-content {
  font-family: poppins;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}
.ok_btn {
  top: 55px !important;
  height: 48px !important;
  width: 226px !important;
  border-radius: 8px !important;
  background: #2787e4 !important;
  color: white !important;
  border: none !important;
  font-size: 16px;
  font-family: Noto Sans;
  margin-bottom: 20px;
}
