.top_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_text {
  font-family: Noto Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3e3c3c;
}

.dashboard-table-title {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
}

th.dashboardView-table-header {
  background-color: #f2f2f2;
  padding: 19px 14px;
  border: none;
}
th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  padding-left: 24px !important;
}

th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

td.green {
  color: #34c759;
  font-weight: 700;
}

td.amber {
  color: #ffa751;
  font-weight: 700;
}

td.red {
  color: #ff2929;
  font-weight: 700;
}

tbody tr td {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  padding-left: 13px !important;
}

td:first-child {
  padding-left: 24px !important;
}
