.home-top-section {
  justify-content: space-between;
  display: flex;
  width: 100%;
}
.text-content {
  width: 100%;
}
.home-title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}
.welcome-text {
  width: 464px;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  color: #555555;
}

.welcome-img1 {
  /* opacity: 0px; */
  width: 450.8px;
  height: 442px;
}

.carousel.slide {
  position: relative !important;
  top: 20px !important;
}
