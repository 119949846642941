.controlCheckName_text {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 450;
  line-height: 30px;
  text-align: left;
}

.controlCheck_box {
  /* width: Fill (1, 029px) px; */
  height: auto;
  padding: 20px;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid #c5c5c5;
  /* opacity: 0px; */
}

.left_col_datas {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.right_col_datas {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.07px;
  text-align: left;
}
