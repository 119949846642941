.custom-table-risk {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.custom-table-risk th,
.custom-table-risk td {
  border: none;
  padding: 10px;
  text-align: left;
}

.custom-table-risk thead tr {
  border: none;
  width: 1, 039px;
  height: 52px;
}
custom-table-risk thead th:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
custom-table-risk thead tr th:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.custom-table-risk thead tr th {
  background-color: #f2f2f2 !important;
  padding: 12px 14px;
  font-weight: bold;
}

.custom-table-risk thead th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.custom-table-risk thead tr th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

/* .custom-table-risk tbody tr {
    border-bottom: 1px solid #e0e0e0;
    border-top: none;
  } */
.data-row {
  border-bottom: 1px solid #e0e0e0;
  border-top: none;
}
.custom-table-risk td {
  padding: 10px 15px;
}

.custom-table-risk thead tr th {
  background-color: #f2f2f2 !important;
  padding: 12px 15px;
  font-weight: 600;
  font-family: Noto Sans;
  font-size: 14px;
  line-height: 27.07px;
}

.custom-table-risk td {
  padding: 10px 15px;
  font-weight: 400;
  font-family: Noto Sans;
  font-size: 12px;
  border: none;
}

.tooltip_risk {
  font-family: Noto Sans;
  display: inline-block;
  padding: 10px;
  max-width: 40%;
  min-width: 150px;
  width: auto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
