.title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}
.search-icon {
  top: 50%;
  left: 30px;
  transform: translateX(150%);
  width: 20px;
  height: 21px;
  pointer-events: none;
}
.top-panel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: -20px;
  padding-left: 0px;
}
.search-bar {
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.search-input-regulatory {
  width: 332px;
  height: 42px;
  border-radius: 12px;
  border: 1px solid #c3d4e9;
  outline: none;
  padding-left: 40px;
  margin-left: 0px !important;
}
.search-input-regulatory::placeholder {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: justified;
  color: #596780;
}
.reset-text-regulatory {
  margin-left: 20px;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
}

.search-button-regulatory {
  margin-left: 20px;
  color: white !important;
  background-color: #2787e4 !important;
  border: none !important;
  border-radius: 8px !important;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 600;
  height: 42px;
  width: 100px;
}
.save-btn-regulatory {
  background-color: #2787e4 !important;
  border: none !important;
  width: 84px;
  height: 42px;
  border-radius: 8px;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 600;
}
/* Hide the default checkbox appearance */
.checkbox {
  /* appearance: none; */
  width: 20px;
  height: 20px;
  border: 1px solid #a9a9a9 !important;
  border-radius: 4px !important;
  outline: none;
  cursor: pointer;
  position: relative;
  top: 4px;
}

/* Checkbox checked state */
.checkbox:checked {
  /* background-color: #a9a9a9; */
  border: none;
}

.checkbox:checked::before {
  content: "";
  position: absolute;
  left: 6px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Optional hover effect */
.custom-checkbox:hover {
  border-color: #a9a9a9 !important;
}
.table-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.custom-table-regulatory {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.custom-table-regulatory th,
.custom-table-regulatory td {
  border: none;
  padding: 10px;
  text-align: left;
}

.custom-table-regulatory thead tr {
  border: none;
  width: 1, 039px;
  height: 52px;
}
custom-table-regulatory thead th:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
custom-table-regulatory thead tr th:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.custom-table-regulatory thead tr th {
  background-color: #f2f2f2 !important;
  padding: 15px 15px;
  font-weight: 500;
  font-family: Noto Sans;
  font-size: 14px;
  gap: 1000px;
}

.custom-table-regulatory thead th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.custom-table-regulatory thead tr th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  margin-left: 50px;
}

.custom-table-regulatory tbody tr {
  border-bottom: 1px solid #e0e0e0;
  border-top: none;
  /* background-color: transparent !important; */
}
.custom-table-regulatory td {
  padding: 10px 15px;
  font-weight: 400;
  font-family: Noto Sans;
  font-size: 12px;
}
.custom-table-regulatory td:last-child {
  padding: 13px 15px;
  font-weight: 400;
  font-family: Noto Sans;
  font-size: 12px;
  margin-left: 50px;
}
.custom-table-regulatory tbody tr:hover {
  background-color: white;
}

.custom-tabl-regulatory tbody tr td:hover {
  background-color: white;
}
