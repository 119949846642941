.left {
  background-color: white;
  padding-left: 100px !important;
}
.left_image_container {
  display: flex;
  align-items: center;
}
.CCS_text {
  font-family: Noto Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.69px;
  width: 295px;
  height: 33px;
}

.pass_container {
  padding-top: 80px;
}
.set_new_password {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.box_Container_sp {
  position: relative;
  top: 25px;
  width: 380px;
  height: 340px;
  border: 1px solid #c5c5c5;
  border-radius: 12px;
  padding: 28px;
}

.password_container_sp {
  position: relative;
  top: 25px;
}
.new_password_text {
  position: relative;
  bottom: 12px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.password_field {
  font-size: 14px;
  height: 45px;
  width: 324px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  padding: 10px;
  outline: none;
}

.password_field:focus {
  border: 1px solid #2871c7;
  outline: none;
}

.confirm_password_container {
  position: relative;
  top: 25px;
}
.confirm_password_text {
  position: relative;
  bottom: 12px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.confirm_password_field {
  font-size: 14px;
  height: 45px;
  width: 324px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  padding: 10px;
  outline: none;
}

.confirm_password_field:focus {
  border: 1px solid #2871c7;
  outline: none;
}
.submit_btn {
  position: relative;
  top: 50px;
  height: 48px;
  width: 324px;
  border-radius: 8px;
  background: #2787e4;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit_btn_text {
  position: relative;
  top: 7px;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.modalStyle-vc .modal-dialog {
  max-width: 361px !important;
}

.modalStyle-vc .modal-content {
  height: 251px !important;
  padding: 0px !important;
  top: 1px;
}
.verification-text {
  font-family: poppins;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
}
.verification-content {
  font-family: poppins;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: center;
}
.ok_btn {
  top: 55px !important;
  height: 48px !important;
  width: 226px !important;
  border-radius: 8px !important;
  background: #2787e4 !important;
  color: white !important;
  border: none !important;
  font-size: 16px;
  font-family: Noto Sans;
  margin-bottom: 20px;
}
.submit-icon {
  cursor: pointer;
  margin-top: 5px;
  text-align: center;
  margin-bottom: 0px;
}
