.logIn_button {
  background-color: white;
  color: black;
  width: 162px;
  height: 52.93px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-top-right-radius: none;
  border-bottom-right-radius: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: background-color 0.3s ease;
  border: 1px solid #c5c5c5;
  border-right: none;
}

.logIn_button.active {
  background-color: #2787e4;
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.login_text {
  position: relative;
  top: 7px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}

.signUp_btn {
  background-color: white;
  color: black;
  width: 162px;
  height: 52.93px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: none;
  border-bottom-left-radius: none;
  transition: background-color 0.3s ease;
  border: 1px solid #c5c5c5;
  border-left: none;
}
.signUp_btn.active {
  background-color: #2787e4;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.signUp_text {
  position: relative;
  top: 7px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}

.credential_container {
  position: relative;
  top: 25px;
}
.email_text {
  position: relative;
  bottom: 12px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.email_field {
  font-size: 14px;
  height: 45px;
  width: 324px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  padding: 10px;
  outline: none;
}

.email_field:focus {
  border: 1px solid #2871c7;
  outline: none;
}

.password_container {
  position: relative;
  top: 30px;
}

.password_text {
  position: relative;
  bottom: 13px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.password_field {
  font-size: 14px;
  height: 45px;
  width: 324px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  padding: 10px;
  outline: none;
}

.password_field:focus {
  border: 1px solid #2871c7;
  outline: none;
}

.logIn_btn {
  position: relative;
  top: 55px;
  height: 48px;
  width: 324px;
  border-radius: 8px;
  background: #2787e4;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logIn_btn_text {
  position: relative;
  top: 7px;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: center;
}

.forget_pass_text {
  position: relative;
  top: 95px;
  left: 90px;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.34px;
  color: #16538e;
  text-decoration: none;
}

.modalStyleSignin .modal-dialog {
  max-width: 409px !important;
}

.modalStyleSignin .modal-content {
  height: 286px !important;
  padding: 20px !important;
  padding-top: 0px !important;
}

.model_header {
  border-bottom: none !important;
  border-top: none !important;
  margin: none !important;
}

.model_header .header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modal-title {
  font-family: poppins;
  font-size: 16px;
  font-weight: 600;
}
.model_header .modalText {
  font-family: poppins;
  font-size: 12px;
  margin: 0;
  margin-top: 5px;
  margin-bottom: -10px;
}
.close-icon {
  cursor: pointer;
  margin-bottom: 30px;
}
.modal-body {
  padding: 0px !important;
  justify-content: center;
}
.email_text_dialog {
  margin-right: 280px;
  font-family: poppins;
  font-size: 16px;
  font-weight: 500;
  align-content: center;
  margin-left: 20px;
}
.email_field_dialog {
  font-size: 14px;
  height: 45px;
  width: 324px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  outline: none;
  margin-left: 20px;
}

.model_footer {
  border-bottom: none !important;
  border-top: none !important;
  align-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}
.send_btn {
  top: 55px !important;
  height: 48px !important;
  width: 324px !important;
  border-radius: 8px !important;
  background: #2787e4 !important;
  color: white !important;
  border: none !important;
  font-size: 16px;
  font-family: Noto Sans;
}

.model_footer .back_to_login {
  font-size: 12px;
  font-family: Noto Sans;
  font-weight: 500;
  text-decoration: none;
  color: #16538e;
}

.modalStyle-msg .modal-dialog {
  max-width: 534px !important;
}

.modalStyle-msg .modal-content {
  height: 268px !important;
  padding: 20px !important;
  padding-top: 0px !important;
}

.submit-icon {
  cursor: pointer;
  margin-top: 30px;
  text-align: center;
}
.pswrd-sent-text {
  font-family: poppins;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.pswrd-sent-content {
  font-family: poppins;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: center;
}
.ok_btn {
  top: 55px !important;
  height: 48px !important;
  width: 226px !important;
  border-radius: 8px !important;
  background: #2787e4 !important;
  color: white !important;
  border: none !important;
  font-size: 16px;
  font-family: Noto Sans;
  margin-bottom: 20px;
}
