.table-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.custom-table-cloud-account {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.custom-table-cloud-account th,
.custom-table-cloud-account td {
  border: none;
  padding: 10px;
  text-align: left;
}

.custom-table-cloud-account thead tr {
  border: none;
  width: 1, 039px;
  height: 52px;
}
custom-table-cloud-account thead th:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
custom-table-cloud-account thead tr th:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.custom-table-cloud-account thead tr th {
  background-color: #f2f2f2 !important;
  padding: 12px 14px;
  font-weight: bold;
}

.custom-table-cloud-account thead th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.custom-table-cloud-account thead tr th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.custom-table-cloud-account tbody tr {
  border-bottom: 1px solid #e0e0e0;
  border-top: none;
  /* background-color: transparent !important; */
}
.custom-table-cloud-account td {
  padding: 10px 15px;
}

.custom-table-cloud-account tbody tr:hover {
  background-color: white;
}

.custom-table-cloud-account tbody tr td:hover {
  background-color: white;
}

.dropdown_integration {
  font-size: 14px !important;
  font-family: Noto Sans !important;
  width: 228px !important;
  height: 36px;
  border-radius: 12px;
  background-color: transparent !important;
  color: #898989 !important;
  text-align: left;
  border: 1px solid #c5c5c5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_integration:focus {
  border: 1px solid #2871c7;
}

.dropdown_integration:hover {
  border: 1px solid #c5c5c5 !important;
}
.dropdown_menu_integration {
  width: 228px;
  padding: 4px;
}

.dropdown-item-integration:hover {
  background-color: #a3ccf4 !important;
  border-radius: 8px;
}

/* .modalStyle-msg-integration .modal-content {
  height: 241px !important;
  padding: 0px !important;
  top: 1px;
  width: 478px !important;
} */

.modalStyle-msg-integration .modal-dialog {
  max-width: 478px !important;
}

.modalStyle-msg-integration .modal-content {
  height: 260px !important;
}
.integration-tool-text1 {
  font-family: poppins;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
}
.integration-tool-text2 {
  font-family: poppins;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: center;
}
.ok_button {
  top: 50px !important;
  height: 46px !important;
  width: 226px !important;
  border-radius: 8px !important;
  background: #2787e4 !important;
  color: white !important;
  border: none !important;
  font-size: 16px;
  font-family: Noto Sans;
  margin-bottom: 20px;
}

.model_footer_integration {
  border: none !important;
  justify-content: center !important;
}

.submit-icon-integration {
  cursor: pointer;
  margin-top: 5px;
  text-align: center;
  margin-bottom: 0px;
}

.modalStyle-msg-servicenow .modal-dialog {
  max-width: 478px !important;
}

.modalStyle-msg-servicenow .modal-content {
  height: 241px !important;
}
.servicenow-tool-text1 {
  font-family: poppins;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
}
.servicenow-tool-text2 {
  font-family: poppins;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: center;
}
.ok_button {
  top: 50px !important;
  height: 46px !important;
  width: 226px !important;
  border-radius: 8px !important;
  background: #2787e4 !important;
  color: white !important;
  border: none !important;
  font-size: 16px;
  font-family: Noto Sans;
  margin-bottom: 20px;
}

.model_footer_servicenow {
  border: none !important;
  justify-content: center !important;
}

.submit-icon-servicenow {
  cursor: pointer;
  margin-top: 5px;
  text-align: center;
  margin-bottom: 0px;
}

.dropdown_activeDirectory {
  font-size: 14px !important;
  font-family: Noto Sans !important;
  width: 228px !important;
  height: 36px;
  background-color: transparent !important;
  color: #898989 !important;
  text-align: left;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_activeDirectory:focus {
  border: 1px solid #2871c7;
}

.dropdown_activeDirectory:hover {
  border: 1px solid #c5c5c5 !important;
}

.dropdown_servcenow {
}

.dropdown_servcenow {
  font-size: 14px !important;
  font-family: Noto Sans !important;
  width: 228px !important;
  height: 36px;
  background-color: transparent !important;
  color: #898989 !important;
  text-align: left;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_servcenow:focus {
  border: 1px solid #2871c7;
}

.dropdown_servcenow:hover {
  border: 1px solid #c5c5c5 !important;
}
