.heading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: #3e3c3c;
}

.sub_content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.cloud_type {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.07px;
  text-align: left;
}

.cloud_service {
  font-size: 10px !important;
  font-weight: 400 !important;
  font-family: Noto Sans !important;
  width: 157px;
  height: 36px;
  background-color: transparent !important;
  color: #1a202c !important;
  text-align: left;
  border: 1px solid #c3d4e9;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cloud_service:focus {
  border: 1px solid #c3d4e9;
}

.cloud_service:hover {
  border: 1px solid #c3d4e9;
}

.dropdown_menu_cloud_service {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #0a0a0a;
  padding: 4px;
}

.dropdown_item_cloud_service:hover {
  background-color: #a3ccf4;
  border-radius: 8px;
}

.source_type {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.07px;
  text-align: left;
}

.service_type {
  font-size: 10px !important;
  font-weight: 400 !important;
  font-family: Noto Sans !important;
  width: 157px;
  height: 36px;
  background-color: transparent !important;
  color: #1a202c !important;
  text-align: left;
  border: 1px solid #c3d4e9;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service_type:focus {
  border: 1px solid #c3d4e9;
}

.service_type:hover {
  border: 1px solid #c3d4e9;
}

.dropdown_menu_service_type {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #0a0a0a;
  padding: 4px;
}

.dropdown_item_service_type:hover {
  background-color: #a3ccf4;
  border-radius: 8px;
}

.reset {
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
  text-align: left;
  cursor: pointer;
}
.reset:hover {
  text-decoration: underline;
}

.search_btn {
  background-color: #2787e4;
  border-radius: 8px;
  /* opacity: 0px; */
  border: transparent;
  height: 36px;
  width: 79px;
}

.search {
  color: white;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
}
