.horiBarCard {
  /* width: 335px; */
  height: 334px;
  padding: 2px 7px 0 7px;
  gap: 18px;
  border-radius: 16px;
  border-color: transparent !important;
}

.cloud_comp_status {
  font-family: Noto Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3e3c3c;
}
