.container {
  margin-left: 0px !important;
  padding-left: 0px;
}
.filter-container {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.dropdown-userStatus-field {
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: Noto Sans !important;
  width: 200px;
  height: 36px;
  background-color: transparent !important;
  color: #596780 !important;
  text-align: left;
  border: 1px solid #c3d4e9;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-userStatus-field:focus {
  border: 1px solid #c3d4e9;
}

.dropdown-userStatus-field:hover {
  border: 1px solid #c3d4e9;
}
.dropdown-userStatus-menu {
  width: 200px;
  padding: 4px;
}

.dropdown-userStatus-item:hover {
  background-color: #a3ccf4;
  border-radius: 12px;
}
.dropdown-userStatus-item {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #0a0a0a;
}

.search-icon {
  top: 50%;
  left: 30px;
  transform: translateX(150%);
  width: 20px;
  height: 21px;
  pointer-events: none;
}

.search-input {
  width: 300px;
  height: 36px;
  border-radius: 12px;
  border: 1px solid #c3d4e9;
  outline: none;
  padding-left: 40px;
  margin-left: 0px !important;
}
.search-input::placeholder {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: justified;
  color: #596780;
}
.reset-text {
  margin-left: 20px;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
}

.search-button {
  margin-left: 20px;
  color: white !important;
  background-color: #2787e4 !important;
  border: none !important;
  border-radius: 8px !important;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
}
.table-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.custom-table th,
.custom-table td {
  border: none;
  padding: 10px;
  text-align: left;
}

.custom-table thead tr {
  border: none;
  width: 1, 039px;
  height: 52px;
}
custom-table thead th:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
custom-table thead tr th:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.custom-table thead tr th {
  background-color: #f2f2f2 !important;
  padding: 12px 15px;
  font-weight: 600;
  font-family: Noto Sans;
  font-size: 14px;
}

.custom-table thead th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.custom-table thead tr th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.custom-table tbody tr {
  border-bottom: 1px solid #e0e0e0;
  border-top: none;
  /* background-color: transparent !important; */
}
.custom-table td {
  padding: 10px 15px;
  font-weight: 400;
  font-family: Noto Sans;
  font-size: 12px;
}

.custom-table tbody tr:hover {
  background-color: white;
}

.custom-table tbody tr td:hover {
  background-color: white;
}
.status-button {
  padding: 5px 10px;
  border-radius: 6px;
  text-align: center;
  font-weight: 400;
  display: inline-block;
  border: 0.5px;
  font-family: Noto Sans;
  font-size: 12px;
  padding-top: 4px;
  padding-right: 12px;
  padding-bottom: 4px;
  padding-left: 12px;
}

.status-active {
  background: #5aef9133;
  color: var(--Colors-Green, #34c759);
  border: 0.5px solid var(--Colors-Green, #34c759);
}

.status-inactive {
  background: #d05a5a33;
  color: var(--Colors-Green, #ff3b30);
  border: 0.5px solid var(--Colors-Green, #ff3b30);
}

.status-new-request {
  background: #6baceb33;
  color: var(--Colors-Green, #007aff);
  border: 0.5px solid var(--Miscellaneous-Sidebar-Text---Selected, #007aff);
}
.action-edit {
  margin-right: 10px;
}
.review-button {
  height: 24px;
  border: 0.5px solid #007aff;
  border-radius: 8px;
  background: #0d7de8cc;
  padding: 0px;
  font-family: Noto Sans;
  font-weight: 500;
  font-size: 12px;
  width: 65px;
}
.review-button:hover {
  background-color: #0d7de8cc;
  border: 0.5px solid #007aff;
  border-radius: 8px;
}
.review-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #3e3c3c;
  margin-bottom: 20px;
}
.review-box {
  width: 593px;
  height: 487px;
  padding: 28px;
  border-radius: 16px;
  border: 1px solid #c5c5c5;
}
.review-field {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.review-field label {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-bottom: 5px;
  margin-right: 5px;
  width: 50%;
}

.review-field input {
  width: 324px;
  height: 36px;
  padding: 14px 12px 14px 12px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background-color: #f8f8f8;
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.34px;
  color: #393939;
}
.review-field textarea {
  width: 324px;
  height: auto;
  padding: 14px 12px 14px 12px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background-color: #f8f8f8;
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 500;
  color: #393939;
}

.review-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.approve-button {
  width: 112px !important;
  height: 48px !important;
  border-radius: 8px !important;
  background-color: #2787e4 !important;
  border: none;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
}
.reject-button {
  width: 95px !important;
  height: 46px !important;
  border-radius: 8px !important;
  background-color: #ff2929 !important;
  border: none;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
}

.edit-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #3e3c3c;
  margin-bottom: 20px;
}
.edit-box {
  width: 521px;
  /* height: 513px; */
  padding: 28px;
  border-radius: 16px;
  border: 1px solid #c5c5c5;
}
.edit-field {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 457px;
  height: 36px;
}

.edit-field label {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-bottom: 5px;
  margin-right: 5px;
  width: 40%;
}

.edit-field input {
  width: 324px;
  height: 36px;
  padding: 14px 12px 14px 12px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  background-color: #f8f8f8;
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.34px;
  color: #393939;
}
/* .dropdown_field {
  width: 290px !important;
  height: 36px !important;
  padding: 14px 12px 14px 12px !important;
  border-radius: 8px !important;
  border: 1px solid #c5c5c5 !important;
  font-family: Noto Sans !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16.34px !important;
  color: #393939 !important;
}
.dropdown_menu {
  width: 290px !important;
} */

.dropdown_edit_user_role {
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: Noto Sans !important;
  width: 291px;
  height: 36px;
  background-color: transparent !important;
  color: #596780 !important;
  text-align: left;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_edit_user_role:focus {
  border: 1px solid #c5c5c5;
}

.dropdown_edit_user_role:hover {
  border: 1px solid #c5c5c5;
}
.dropdown_edit_user_menu_role {
  width: 291px;
  padding: 4px;
}

.dropdown_edit_user_item_role:hover {
  background-color: #a3ccf4;
  border-radius: 8px;
}
.dropdown_edit_user_item_role {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #0a0a0a;
}

.edit-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.update-button {
  width: 164px !important;
  height: 48px !important;
  border-radius: 8px !important;
  background-color: #2787e4 !important;
  border: none;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
}
.cancel-button {
  width: 164px !important;
  height: 48px !important;
  border-radius: 8px !important;
  background-color: #ff2929 !important;
  border: none;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
}

.modalStyle .modal-dialog {
  max-width: 409px !important;
}

.modalStyle .modal-content {
  height: 286px !important;
  padding: 10px !important;
  padding-top: 10px !important;
}

.model_header {
  border-bottom: none !important;
  border-top: none !important;
  margin: none !important;
}

.model_header .header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modal-title {
  font-family: poppins;
  font-size: 16px;
  font-weight: 600;
}
.model_header .modalText {
  font-family: poppins;
  font-size: 12px;
  margin: 0;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.close-icon {
  cursor: pointer;
  margin-bottom: 30px;
}
.modal-body {
  padding: 0px !important;
  justify-content: left !important;
}
.rejection_text_dialog {
  margin-right: 150px !important;
  font-family: poppins;
  font-size: 16px;
  font-weight: 500;
  align-content: left !important;
  margin-left: 20px;
}
.rejection_field_dialog {
  font-size: 14px;
  height: 48px !important;
  width: 324px;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  outline: none;
  margin-left: 20px;
}

.model_footer {
  border-bottom: none !important;
  border-top: none !important;
  align-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  margin-top: 0px;
}

.modalStyle-msg .modal-dialog {
  max-width: 361px !important;
}

.modalStyle-msg .modal-content {
  height: 251px !important;
  padding: 0px !important;
  top: 1px;
}

.reject-icon {
  cursor: pointer;
  margin-top: 20px !important;
  text-align: center;
}
.user-rejected-text {
  font-family: poppins;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
}
.success-rejected-content {
  font-family: poppins;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: center;
}
.ok_btn {
  top: 55px !important;
  height: 48px !important;
  width: 226px !important;
  border-radius: 8px !important;
  background: #2787e4 !important;
  color: white !important;
  border: none !important;
  font-size: 16px;
  font-family: Noto Sans;
  margin-bottom: 20px;
}
.submit-icon {
  cursor: pointer;
  margin-top: 5px;
  text-align: center;
  margin-bottom: 0px;
}
.modal-update .modal-dialog {
  max-width: 582px !important;
}

.modal-update .modal-content {
  height: 241px !important;
}
.user-updated-text {
  font-family: poppins;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
}
.success-update-content {
  font-family: poppins;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: center;
}
.ok_button {
  top: 50px !important;
  height: 46px !important;
  width: 226px !important;
  border-radius: 8px !important;
  background: #2787e4 !important;
  color: white !important;
  border: none !important;
  font-size: 16px;
  font-family: Noto Sans;
  margin-bottom: 20px;
}
.submit-icon {
  cursor: pointer;
  margin-top: 5px;
  text-align: center;
  margin-bottom: 0px;
}
.update-model-footer {
  border: none !important;
  justify-content: center !important;
}

.custom-modal .modal-header,
.custom-modal .modal-footer,
.custom-modal .modal-body {
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}
/* Set fixed width and height for the modal */
.custom-modal .modal-dialog {
  max-width: 532px; /* Set your desired width */
  width: 100%; /* Ensures responsiveness */
  height: auto;
}

.custom-modal .modal-content {
  height: 162px; /* Set your desired height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.custom-modal .modal-header {
  margin-bottom: 0px;
  padding-bottom: 10px;
}
.custom-modal .modal-body {
  font-family: poppins;
  font-size: 16px;
  font-weight: 400;
  align-content: left;
  margin-left: 30px;
}

.custom-modal .modal-footer {
  justify-content: center; /* Centers the footer buttons */
  margin-top: 0px;
  padding-top: 10px;
}
.confirm-btn {
  width: 110px !important;
  height: 46px !important;
  border-radius: 8px !important;
  background-color: #2787e4;
  font-family: Noto Sans !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  border: none !important;
  color: white;
}

.cancel-btn {
  width: 99px !important;
  height: 46px !important;
  border-radius: 8px !important;
  background-color: #ff2929 !important;
  font-family: Noto Sans !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  border: none !important;
}
