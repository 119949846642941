/* .left-navbar {
  width: 20%;
  min-width: 150px;
  height: 100vh;
  position: fixed;
  padding: 10px;
  background: #ffffff;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
} */

.left-sidebar {
  background: #ffffff;
  padding: 10px;
  top: 0;
  left: 0;
}

.nav-link {
  position: relative;
  top: 15px;
  height: 55px;
  border-radius: 12px;
  color: #596780;
}
.nav-link:hover {
  color: #292d32;
  background-color: #f1f1f1;
  border-radius: 12px;
  font-weight: 600;
}

.icon_text {
  font-family: "Noto Sans";
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  position: relative;
  left: 26px;
  text-decoration: none;
}

.setting_text {
  font-family: "Noto Sans";
  font-weight: 500px;
  font-size: 18px;
  line-height: 27px;
  position: relative;
  left: 26px;
}

.signout_text {
  font-family: "Noto Sans";
  font-weight: 500px;
  font-size: 18px;
  line-height: 27px;
  position: relative;
  left: 26px;
}

.nav {
  position: relative;
  gap: 4px;
}

.icons {
  position: relative;
  left: 10px;
}
.setting_icons {
  position: relative;
  left: 10px;
}
.signout_icons {
  position: relative;
  left: 10px;
}

.logo {
  position: relative;
  /* opacity: 0px; */
}
.logo_text {
  font-family: "Noto Sans";
  /* width: 179px;
  height: 20px; */
  /* gap: 0px;
  opacity: 0px; */
  font-weight: 700;
  font-size: 14.6px;
  /* line-height: 19.88px; */
}

.main_menu {
  position: relative;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 500;
  color: #90a3bf;
  line-height: 21px;
  letter-spacing: -0.02em;
  left: 25px;
}

.preferences {
  position: relative;
  top: 40px;
  left: 25px;
  font-family: Plus Jakarta Sans;
  font-weight: 500;
  color: #90a3bf;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.02em;
}
/* .selecteds {
  background-color: #2787e4;
} */

/* .active > .home_icon {
  content: url("../Icons/homeWhite.svg");
} */
