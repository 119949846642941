.title-inventory {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: #3e3c3c;
}
.filter {
  display: flex;
}
.field {
  display: flex;
  align-items: center;
  gap: 16px;
}
.cloud-type {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
}

.dropdown_field_cloud_type {
  font-size: 10px !important;
  font-family: Noto Sans !important;
  width: 157px !important;
  height: 36px;
  background-color: transparent !important;
  color: #1a202c !important;
  text-align: left;
  border: 1px solid #c3d4e9;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown_field_cloud_type::after {
  font-size: 1rem;
  color: #90a3bf;
}
.dropdown_field_cloud_type:focus {
  border: 1px solid #c3d4e9;
}

.dropdown_field_cloud_type:hover {
  border: 1px solid #c3d4e9 !important;
}
.dropdown_menu_cloud_type {
  width: 157px;
  padding: 4px;
}
.dropdown_item_cloud_type {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.005em;
  text-align: left;
}
.dropdown_item_cloud_type:hover {
  background-color: #a3ccf4;
  border-radius: 8px;
}
.dropdown_field_resource_type {
  font-size: 10px !important;
  font-family: Noto Sans !important;
  width: 161px !important;
  height: 36px;
  background-color: transparent !important;
  color: #1a202c !important;
  text-align: left;
  border: 1px solid #c3d4e9;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_field_resource_type::after {
  font-size: 1rem;
  color: #90a3bf;
}

.dropdown_field_resource_type:focus {
  border: 1px solid #c3d4e9;
}

.dropdown_field_resource_type:hover {
  border: 1px solid #c3d4e9 !important;
}
.dropdown_menu_resource_type {
  width: 161px;
  padding: 4px;
}
.dropdown_item_resource_type {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.005em;
  text-align: left;
}
.dropdown_item_resource_type:hover {
  background-color: #a3ccf4;
  border-radius: 8px;
}
.dropdown_field_asset_type {
  font-size: 10px !important;
  font-family: Noto Sans !important;
  width: 157px !important;
  height: 36px;
  background-color: transparent !important;
  color: #1a202c !important;
  text-align: left;
  border: 1px solid #c3d4e9;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown_field_asset_type::after {
  font-size: 1rem;
  color: #90a3bf;
}
.dropdown_field_asset_type:focus {
  border: 1px solid #c3d4e9;
}

.dropdown_field_asset_type:hover {
  border: 1px solid #c3d4e9 !important;
}
.dropdown_menu_asset_type {
  width: 157px;
  padding: 4px;
}

.dropdown_item_asset_type:hover {
  background-color: #a3ccf4;
  border-radius: 8px;
}
.dropdown_item_asset_type {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.005em;
  text-align: left;
}
.dropdown_field_region {
  font-size: 10px !important;
  font-family: Noto Sans !important;
  width: 160px !important;
  height: 36px;
  background-color: transparent !important;
  color: #1a202c !important;
  text-align: left;
  border: 1px solid #c3d4e9;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown_field_region::after {
  font-size: 1rem;
  color: #90a3bf;
}
.dropdown_field_region:focus {
  border: 1px solid #c3d4e9;
}

.dropdown_field_region:hover {
  border: 1px solid #c3d4e9 !important;
}
.dropdown_menu_region {
  width: 160px;
  padding: 4px;
}

.dropdown_item_region:hover {
  background-color: #a3ccf4;
  border-radius: 8px;
}
.dropdown_item_region {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.005em;
  text-align: left;
}
.search-icon-inventory {
  top: 50%;
  left: 30px;
  transform: translateX(150%);
  width: 20px;
  height: 21px;
  pointer-events: none;
}
.top-panel-inventory {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: -20px;
  padding-left: 0px;
}
.search-bar-inventory {
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.search-input-inventory {
  width: 300px;
  height: 36px;
  border-radius: 12px;
  border: 1px solid #c3d4e9;
  outline: none;
  padding-left: 40px;
  margin-left: 0px !important;
}
.search-input-inventory::placeholder {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: justified;
  color: #596780;
}
.reset-text-inventory {
  margin-left: 20px;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
}

.search-button-inventory {
  margin-left: 20px;
  color: white !important;
  background-color: #2787e4 !important;
  border: none !important;
  border-radius: 8px !important;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 600;
  height: 36px;
  width: 79px;
}
.input-field {
  width: 145px;
  height: 28px;
  border-radius: 8px;
  padding: 5px;
  border: 1px solid #c5c5c5;
}
.input-field::placeholder {
  font-family: Noto Sans;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  color: #898989;
}
th {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
}
td {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
}

.dropdown_field_business_unit {
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: Noto Sans !important;
  width: 164px;
  height: 28px;
  background-color: transparent !important;
  color: #898989 !important;
  text-align: left;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_field_business_unit:focus {
  border: 1px solid #c5c5c5;
}

.dropdown_field_business_unit:hover {
  border: 1px solid #c5c5c5;
}
.dropdown_menu_business_unit {
  width: 164px;
  padding: 4px;
}

.dropdown_item_business_unit:hover {
  background-color: #a3ccf4;
  border-radius: 8px;
}
.dropdown_item_business_unit {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #0a0a0a;
}

/* Integration Table */

.custom-table-integration {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.custom-table-integration th,
.custom-table-integration td {
  border: none;
  padding: 10px;
  text-align: left;
}

.custom-table-integration thead tr {
  border: none;
  width: 1, 039px;
  height: 52px;
}
custom-table-integration thead th:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
custom-table-integration thead tr th:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.custom-table-integration thead tr th {
  background-color: #f2f2f2 !important;
  padding: 12px 14px;
  font-weight: bold;
}

.custom-table-integration thead th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.custom-table-integration thead tr th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.custom-table-integration tbody tr {
  border-bottom: 1px solid #e0e0e0;
  border-top: none;
  /* background-color: transparent !important; */
}
.custom-table-integration td {
  padding: 10px 10px;
}

.custom-table-integration tbody tr:hover {
  background-color: white;
}

.custom-table-integration tbody tr td:hover {
  background-color: white;
}
