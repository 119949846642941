.add-business-btn{
    background-color: #2787E4 !important;
    border: none !important;
    width: 179px;
    height: 36px;
    border-radius: 12px;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 600;
}
.table-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
   
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
  }
   
  .custom-table th, .custom-table td {
    border: none;
    padding: 10px;
    text-align: left;
  }

  .custom-table thead tr{
    border: none;
    width: 1,039px;
    height: 52px;
  }
   

  .custom-table thead tr th {
    background-color: #f9f9f9 !important;
    padding: 12px 15px;
    font-weight: 600;
    font-family: Noto Sans;
    font-size: 14px;
  }
   
  .custom-table thead th:first-child{
    border-top-left-radius: 10px !important;
    border-bottom-left-radius:10px !important;
  }
  .custom-table  thead tr th:last-child{
    border-top-right-radius: 10px !important;
    border-bottom-right-radius:10px !important;
  }
   
 
  .custom-table tbody tr {
    border-bottom: 1px solid #e0e0e0; 
    border-top: none;
    /* background-color: transparent !important; */
  }
  .custom-table td {
    padding: 10px 15px;
    font-weight: 400;
    font-family: Noto Sans;
    font-size: 12px;
  }
   
  .custom-table tbody tr:hover {
    background-color: white;
  }

  .custom-table tbody tr td:hover {
    background-color: white ;
  }

  .create-bu-title{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color:#3E3C3C;
    margin-bottom: 20px;
  }
.create-bu-box {
    width: 517px;
height: 252px;
padding: 28px;
border-radius: 16px;
border: 1px solid #C5C5C5;
  }
  .create-bu-field {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    /* width: 457px;
    height: 36px; */
  }
   
  .create-bu-field label {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 5px;
    margin-right: 5px;
    width: 40%;
  }
   
  .create-bu-field input {
    width: 324px;
    height: 36px;
    padding: 14px 12px 14px 12px;
    border-radius: 8px;
    border: 1px solid #C5C5C5;
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.34px;
    color: #393939;
  }
  .create-bu-field textarea {
    width: 324px;
    padding: 14px 12px 14px 12px;
    border-radius: 8px;
    border: 1px solid #C5C5C5;
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.34px;
    color: #393939;
  }

  .create-bu-actions{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }

  .save-button{
    width: 84px !important;
    height: 48px !important;
    border-radius: 8px !important;
    background-color: #2787E4 !important;
    border: none;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.79px;

  }
.cancel-button{
    width: 99px !important;
    height: 48px !important;
    border-radius: 8px !important;
    background-color: #FF2929 !important;
    border: none;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.79px;
  }
  .modal-createbu .modal-dialog {
    max-width: 462px !important;
  }
  
  .modal-createbu .modal-content {
    height: 247px !important;
  }
  .user-createbu-text {
    font-family: poppins;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
  }
  .success-createbu-content {
    font-family: poppins;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
    text-align: center;
  }
  .ok_button {
    top: 50px !important;
    height: 46px !important;
    width: 226px !important;
    border-radius: 8px !important;
    background: #2787e4 !important;
    color: white !important;
    border: none !important;
    font-size: 16px;
    font-family: Noto Sans;
    margin-bottom: 20px;
  }
  .submit-icon {
    cursor: pointer;
    margin-top: 5px;
    text-align: center;
    margin-bottom: 0px;
  }
  .createbu-model-footer{
    border: none !important;
    justify-content: center !important;
  }
  .modal-editbu .modal-dialog {
    max-width: 484px !important;
  }
  
  .modal-editbu .modal-content {
    height: 241px !important;
  }
  .user-editbu-text {
    font-family: poppins;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
  }
  .success-editbu-content {
    font-family: poppins;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
    text-align: center;
  }
  .ok_button {
    top: 50px !important;
    height: 46px !important;
    width: 226px !important;
    border-radius: 8px !important;
    background: #2787e4 !important;
    color: white !important;
    border: none !important;
    font-size: 16px;
    font-family: Noto Sans;
    margin-bottom: 20px;
  }
  .submit-icon {
    cursor: pointer;
    margin-top: 5px;
    text-align: center;
    margin-bottom: 0px;
  }
  .editbu-model-footer{
    border: none !important;
    justify-content: center !important;
  }