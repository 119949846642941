.pieChartCard {
  /* width: 312px; */
  height: 333.5px;
  padding: 10px 26px 20px 26px;
  gap: 18px;
  border-radius: 16px;
  border-color: transparent !important;
}
.shadowCard {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cloud_Acc_detail {
  font-family: Noto Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 0;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3e3c3c;
}
