.table-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.custom-table-cloud-ccount {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.custom-table-cloud-account th,
.custom-table-cloud-account td {
  border: none;
  padding: 10px;
  text-align: left;
}

.custom-table-cloud-account thead tr {
  border: none;
  width: 1, 039px;
  height: 52px;
}
custom-table-cloud-account thead th:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
custom-table-cloud-account thead tr th:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.custom-table-cloud-account thead tr th {
  background-color: #f2f2f2 !important;
  padding: 12px 14px;
  font-weight: bold;
}

.custom-table-cloud-account thead th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.custom-table-cloud-account thead tr th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.custom-table-cloud-account tbody tr {
  border-bottom: 1px solid #e0e0e0;
  border-top: none;
}
.custom-table-cloud-account td {
  padding: 10px 15px;
}

.custom-table-cloud-account thead tr th {
  background-color: #f2f2f2 !important;
  padding: 12px 15px;
  font-weight: 600;
  font-family: Noto Sans;
  font-size: 14px;
  line-height: 27.07px;
}

.custom-table-cloud-account td {
  padding: 10px 15px;
  font-weight: 400;
  font-family: Noto Sans;
  font-size: 12px;
}

.dropdown_csp_field {
  font-size: 14px !important;
  font-family: Noto Sans !important;
  width: 240px !important;
  height: 36px;
  background-color: transparent !important;
  color: #596780 !important;
  text-align: left;
  border: 1px solid #c3d4e9;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_csp_field:focus {
  border: 1px solid #2871c7;
}

.dropdown_csp_field:hover {
  border: 1px solid #c3d4e9 !important;
}
.dropdown_menu_csp {
  width: 240px;
  padding: 4px;
}

.dropdown_item_csp:hover {
  background-color: #a3ccf4 !important;
  border-radius: 8px;
}
.dropdown_item_csp {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.search-icon-cloudAccount {
  top: 50%;
  left: 30px;
  transform: translateX(150%);
  width: 20px;
  height: 21px;
  pointer-events: none;
}
.top-panel-cloudAccount {
  display: flex;
  margin-bottom: 15px;
}
.search-bar-cloudAccount {
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.search-input-cloudAccount {
  width: 300px;
  height: 36px;
  border-radius: 12px;
  border: 1px solid #c3d4e9;
  outline: none;
  padding-left: 40px;
  margin-left: 0px !important;
}
.search-input-cloudAccount::placeholder {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: justified;
  color: #596780;
}
.reset-text-cloudAccount {
  margin-left: 20px;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
  cursor: pointer;
}

.search-button-cloudAccount {
  margin-left: 20px;
  color: white !important;
  background-color: #2787e4 !important;
  border: none !important;
  border-radius: 8px !important;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 600;
  height: 36px;
  width: 79px;
}

.dropdown_cloudAccountTable {
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: Noto Sans !important;
  width: 228px !important;
  height: 36px;
  background-color: transparent !important;
  color: #898989 !important;
  text-align: left;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_cloudAccountTable:focus {
  border: 1px solid #c5c5c5;
}

.dropdown_cloudAccountTable:hover {
  border: 1px solid #c5c5c5;
}
.custom-modal-delete .modal-header,
.custom-modal-delete .modal-footer,
.custom-modal-delete .modal-body {
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.custom-modal-delete .modal-dialog {
  max-width: 614px; /* Set your desired width */
  width: 100%; /* Ensures responsiveness */
  height: auto;
}

.custom-modal-delete .modal-content {
  height: 162px; /* Set your desired height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.custom-modal-delete .modal-header {
  margin-bottom: 0px;
  padding-bottom: 10px;
}
.custom-modal-delete .modal-body {
  font-family: poppins;
  font-size: 16px;
  font-weight: 400;
  align-content: left;
  margin-left: 30px;
}

.custom-modal-delete .modal-footer {
  justify-content: center; /* Centers the footer buttons */
  margin-top: 0px;
  padding-top: 10px;
}
.confirm-btn-delete {
  width: 110px !important;
  height: 46px !important;
  border-radius: 8px !important;
  background-color: #2787e4;
  font-family: Noto Sans !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  border: none !important;
}
.cancel-btn-delete {
  width: 99px !important;
  height: 46px !important;
  border-radius: 8px !important;
  background-color: #ff2929 !important;
  font-family: Noto Sans !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  border: none !important;
}

.modalStyle-msg-cloud-account-edit .modal-content {
  height: 275px !important;
}

.dropdown_field_aws_bu {
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: Noto Sans !important;
  width: 228px;
  height: 36px;
  background-color: transparent !important;
  color: #898989 !important;
  text-align: left;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px !important;
}

.dropdown_field_aws_bu:focus {
  border: 1px solid #c5c5c5;
}

.dropdown_field_aws_bu:hover {
  border: 1px solid #c5c5c5;
}
.dropdown_menu_aws_bu {
  width: 228px;
  padding: 4px;
}

.dropdown_item_aws_bu:hover {
  background-color: #a3ccf4;
  border-radius: 8px;
}
.dropdown_item_aws_bu {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #0a0a0a;
}
