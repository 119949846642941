.topHeading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.topSection {
  display: flex;
  align-items: center;
}

.regStandText {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
}

.regulatoryStandardDropDown1 {
  font-size: 10px !important;
  font-weight: 400 !important;
  font-family: Noto Sans !important;
  width: 157px;
  height: 36px;
  line-height: 13.62px;
  background-color: transparent !important;
  color: #1a202c !important;
  text-align: left;
  border: 1px solid #c3d4e9;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.regulatoryStandardDropDown1:focus {
  border: 1px solid #c3d4e9;
}

.regulatoryStandardDropDown1:hover {
  border: 1px solid #c3d4e9;
}

.dropdownMenuRegulatoryStandard1 {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #0a0a0a;
  padding: 4px;
}

.dropdownItemRegulatoryStandard1:hover {
  background-color: #a3ccf4;
  border-radius: 8px;
}

.controlTypeText {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
}

.regulatoryStandardDropDown2 {
  font-size: 10px !important;
  font-weight: 400 !important;
  font-family: Noto Sans !important;
  width: 157px;
  height: 36px;
  line-height: 13.62px;
  background-color: transparent !important;
  color: #1a202c !important;
  text-align: left;
  border: 1px solid #c3d4e9;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.regulatoryStandardDropDown2:focus {
  border: 1px solid #c3d4e9;
}

.regulatoryStandardDropDown2:hover {
  border: 1px solid #c3d4e9;
}

.dropdownMenuRegulatoryStandard2 {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #0a0a0a;
  padding: 4px;
}

.dropdownItemRegulatoryStandard2:hover {
  background-color: #a3ccf4;
  border-radius: 8px;
}

.complianceText {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
}

.regulatoryStandardDropDown3 {
  font-size: 10px !important;
  font-weight: 400 !important;
  font-family: Noto Sans !important;
  width: 126px;
  height: 36px;
  line-height: 13.62px;
  background-color: transparent !important;
  color: #1a202c !important;
  text-align: left;
  border: 1px solid #c3d4e9;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.regulatoryStandardDropDown3:focus {
  border: 1px solid #c3d4e9;
}

.regulatoryStandardDropDown3:hover {
  border: 1px solid #c3d4e9;
}

.dropdownMenuRegulatoryStandard3 {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #0a0a0a;
  padding: 4px;
}

.dropdownItemRegulatoryStandard3:hover {
  background-color: #a3ccf4;
  border-radius: 8px;
}

.resetBtn {
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
  text-align: left;
  cursor: pointer;
}
.resetBtn:hover {
  text-decoration: underline;
}

/* .searchBtn {
  width: 77px;
  height: 36px;
  border: none;
  gap: 5px;
  border-radius: 8px;
  opacity: 0px;
  background-color: #2787e4;
  color: white;
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
} */

.policyControlBtn {
  width: 172px;
  height: 36px;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid #007aff;
  /* opacity: 0px; */
  background-color: transparent;
}

.policyControlText {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.34px;
  text-align: center;
  color: #1a202c;
  position: relative;
  bottom: 1px;
}

.policyCount {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: center;
  color: #2787e4;
  margin-left: 17px;
}

.riskRegisterBtn {
  width: 126px;
  height: 36px;
  gap: 5px;
  border-radius: 8px;
  /* opacity: 0px; */
  background-color: #2787e4;
  border: none;
}

.riskRegisterText {
  color: white;
  font-family: Noto Sans;
  font-size: 15px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
}

.evidenceDropDown {
  font-size: 10px !important;
  font-weight: 400 !important;
  font-family: Noto Sans !important;
  width: 156px;
  height: 24px;
  line-height: 13.62px;
  background-color: transparent !important;
  color: #1a202c !important;
  text-align: left;
  border: 1px solid #c3d4e9;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.evidenceDropDown:focus {
  border: 1px solid #c3d4e9;
}

.evidenceDropDown:hover {
  border: 1px solid #c3d4e9;
}

.evidenceDropDownMenu {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #0a0a0a;
  padding: 4px;
}

.evidenceDropDownItem:hover {
  background-color: #a3ccf4;
  border-radius: 8px;
}
