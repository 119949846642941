.dashboard_container {
  display: flex !important;
  flex-direction: column !important;
  height: auto;
  min-height: 100vh;
  background-color: #f6f7f9 !important;
  /* width: 100vw; */
}

.main-section {
  display: flex !important;
  flex: 1 !important;
  margin-top: 80px;
  left: 0;
}

.content-area {
  /* margin-top: -7px; */
  margin-left: 20%;
  width: 80%;
  overflow-y: auto;
  /* height: calc(100vh-80px); */
}
.content-box {
  background-color: white;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  margin-bottom: 17px;
}

.context_box_dashboard {
  /* padding: 5px; */
  margin: 10px;
  border-radius: 10px;
  margin-bottom: 17px;
}
