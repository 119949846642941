.top_Navbar {
  width: 80%;
  height: 73px;
  background-color: white;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  border-left: 5px solid #f6f7f9 !important;
  position: fixed;
  z-index: 1000;
}

.right-content {
  display: flex;
  align-items: center;
}

.test_img {
  margin-left: 15px;
}
.profile-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #898989;
  color: white;
  font-size: 1.2em; 
  margin-left: 20px;
}

.email-role {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px; 
  font-family: Noto Sans;
font-size: 16px;
font-weight: 400;
color: #1A202C;
}
.email-role p,
.email-role .role_img {
  margin: 0; 
  padding: 0; 
}

.role_img {
  width: 61px; 
  height: 24px; 
}
