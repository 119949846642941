.left {
  background-color: white;
  /* padding-left: 100px !important; */
}
.left_image_container {
  display: flex;
  align-items: center;
}
.CCS_text {
  font-family: Noto Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.69px;
  width: 295px;
  height: 33px;
}

.text_container {
  padding-top: 20px;
}
.get_started_now {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}
.credential_to_access {
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
}

.box_Container {
  position: relative;
  top: 25px;
  width: 380px;
  height: 420px;
  border: 1px solid #c5c5c5;
  border-radius: 12px;
  padding: 28px;
}

.box_Container_signUp {
  position: relative;
  width: 380px;
  border: 1px solid #c5c5c5;
  border-radius: 12px;
  padding: 28px;
  transition: all 0.6s ease;
  overflow: hidden;
  transform: scale(1.01);
}

.right {
  background: #2787e4;
  padding: 38px;
}
.right_box_text {
  font-family: Poppins;
  font-size: 23px;
  font-weight: 500;
  line-height: 36px;
  color: white;
}

.rs_1 {
  height: 552.31px;
  width: 476px;
}
